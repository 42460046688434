import React, { useRef } from 'react';
import { Box, Divider, Paper, List, ListItemIcon, ListItemButton, Typography } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AddBoxIcon from '@mui/icons-material/AddBox';
import LockIcon from '@mui/icons-material/Lock';
import { useNavigate } from 'react-router-dom';

function Sidebar() {
  const navigate = useNavigate();
  const menuRef = useRef(null);

  const MenuList = React.forwardRef((props, ref) => {
    const menuItems = [
      { path: '/', icon: <DashboardIcon className='menuIcon' sx={{ fontSize: 40 }} />, text: 'Manage' },
      { path: '/request', icon: <AddBoxIcon className='menuIcon' sx={{ fontSize: 40 }} />, text: 'Request' },
      { path: '/generate-csr', icon: <LockIcon className='menuIcon' sx={{ fontSize: 40 }} />, text: 'Generate CSR' },
    ];

    const handleRedirectAndClose = (path) => {
      try {
        console.log(`Redirecionando para: ${path}`);
        navigate(path);
      } catch (error) {
        console.error('Erro ao redirecionar:', error);
      }
    };    

    return (
      <Box sx={{ px: 1 }}>
        <List className="menu">
          {menuItems.map((item, index) => (
            <ListItemButton
              key={index}
              onClick={() => handleRedirectAndClose(item.path)}
              sx={{ 
                borderRadius: 1,
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <Typography variant="body2" sx={{ fontSize: '14px' }}>{item.text}</Typography>
            </ListItemButton>
          ))}
        </List>
      </Box>
    );
  });

  return (
    <Paper sx={{ position: 'fixed', width: 240, height: '100vh', top: 0, left: 0 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box p={2}>
          <div className="logo-section">
            <img src="https://www.trustcert.com.br/wp-content/uploads/2023/06/trustcert-logo.png" alt="Logo" />
          </div>
        </Box>
        <Divider />
        <Box sx={{ p: 2, display: 'auto', flexGrow: 1 }}>
          <MenuList ref={menuRef} />
        </Box>
        <Divider />
        <Box sx={{ p: 2, textAlign: 'center' }}>
          <Typography variant="body2">v.1.0.2</Typography>
        </Box>
      </Box>
    </Paper>
  );
}

export default Sidebar;
