import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { 
  Paper, 
  Table, 
  TableContainer, 
  TableHead, 
  TableBody, 
  TableRow, 
  TableCell, 
  Tooltip,
  IconButton, 
  Button, 
  Divider } from '@material-ui/core';
import { 
  Edit as EditIcon, 
  Delete as DeleteIcon, 
  Add as AddIcon,
  Refresh as RefreshIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon
 } from '@material-ui/icons';

 import '../../styles.css';
import Header from '../MainLayout/Header';
import Sidebar from '../MainLayout/Sidebar';
import AddCustomerForm from './addCustomerForm';

function CustomerPage() {
  const user = useSelector((state) => state.auth.user);
  const [users, setUsers] = useState([]);
  const [isAddCustomerDialogOpen, setAddCustomerDialogOpen] = useState(false);
  const [sorting, setSorting] = useState({ field: '', order: 'asc' });

  const handleOpenAddCustomerDialog = () => {
    setAddCustomerDialogOpen(true);
  };

  const handleCloseAddCustomerDialog = () => {
    setAddCustomerDialogOpen(false);
  };

  const fetchData = async () => {
    try {
      let customerId = user.customer_id;
      let isAdmin = user.isAdmin;
  
      let apiUrl = customerId && customerId.length === 49 && customerId.charAt(16) === '1'
        ? `${process.env.REACT_APP_API_URL}/v1/customer/`
        : `${process.env.REACT_APP_API_URL}/v1/customer/${customerId}`;

      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
      }

      const responseData = await response.json();

      if (Array.isArray(responseData)) {
        setUsers(responseData);
      } else {
        console.error('API did not return an array:', responseData);
      }
    } catch (error) {
      console.error('Erro ao buscar os clientes:', error);
    }
  };

  useEffect(() => {
    console.log(`Usuário ${user.username} está autenticado.`);
    console.log(`Customer ID do usuário: ${user.customer_id}`);
  
    fetchData();
  }, [user]);

  const handleEditCustomer = (customerId) => {
    // Implemente a lógica para editar o usuário com o ID fornecido
    console.log(`Edit customer id: ${customerId}`);
  };

  const handleDeleteCustomer = (customerId) => {
    // Implemente a lógica para excluir o usuário com o ID fornecido
    console.log(`Delete customer id: ${customerId}`);
  };

  const handleAddCustomer = async (newCustomer) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/customer`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newCustomer),
      });
  
      if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
      }
  
      const responseData = await response.json();
      console.log('New customer added:', responseData);
  
      // Fetch the updated list of customers from the API and update the state
      fetchData();
    } catch (error) {
      console.error('Error adding new customer:', error);
    }
  };

  const handleRefresh = () => {
    fetchData();
    setAddCustomerDialogOpen(false);
  };

  const sortData = (data, field, order) => {
    return data.slice().sort((a, b) => {
      if (order === 'asc') {
        if (a[field] < b[field]) {
          return -1;
        } else if (a[field] > b[field]) {
          return 1;
        }
        return 0;
      } else {
        if (a[field] > b[field]) {
          return -1;
        } else if (a[field] < b[field]) {
          return 1;
        }
        return 0;
      }
    });
  };

  const handleSort = (field) => {
    if (sorting.field === field) {
      setSorting({ field, order: sorting.order === 'asc' ? 'desc' : 'asc' });
      // Classifique os dados com base no campo e na ordem atual
      const sortedData = sortData(users, field, sorting.order);
      setUsers(sortedData);
    } else {
      setSorting({ field, order: 'asc' });
      // Classifique os dados com base no campo em ordem ascendente
      const sortedData = sortData(users, field, 'asc');
      setUsers(sortedData);
    }
  };

  return (
    <>
      <Header />
      <Sidebar />
        <main>
          <div className='page-content'>
            <h2 className="userTitle">Manage Customers</h2>
            <Button
              className="addCustomerButton"
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleOpenAddCustomerDialog}
            >
              Add Customer
            </Button>
            <Tooltip title="Refresh Data">
              <IconButton
                className="refreshButton"
                onClick={handleRefresh}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
            <AddCustomerForm
              open={isAddCustomerDialogOpen}
              onClose={handleCloseAddCustomerDialog}
              onAddCustomer={handleAddCustomer}
            />
            <Divider />
            <TableContainer component={Paper} className="tableContainer">
              <Table className="table">
                <TableHead>
                  <TableRow>
                    <TableCell 
                      className="tableCell"  
                      onClick={() => handleSort('id')}
                      >
                        ID
                        {
                          sorting.field === 'id' && (
                            sorting.order === 'asc' ? (
                            <ArrowUpwardIcon className="sortIcon" />
                            ) : (
                            <ArrowDownwardIcon className="sortIcon" />
                            )
                        )}
                    </TableCell>
                    <TableCell 
                      className="tableCell" 
                      onClick={() => handleSort('name')}
                      >
                        Name
                        {sorting.field === 'name' && (
                          sorting.order === 'asc' ? (
                            <ArrowUpwardIcon className="sortIcon" />
                          ) : (
                            <ArrowDownwardIcon className="sortIcon" />
                          )
                        )}
                    </TableCell>
                    <TableCell 
                      className="tableCell" 
                      onClick={() => handleSort('phone')}
                      >
                        Phone
                        {sorting.field === 'phone' && (
                          sorting.order === 'asc' ? (
                            <ArrowUpwardIcon className="sortIcon" />
                          ) : (
                            <ArrowDownwardIcon className="sortIcon" />
                          )
                        )}
                    </TableCell>
                    <TableCell 
                      className="tableCell" 
                      onClick={() => handleSort('cnpj')}
                      >
                        CNPJ
                        {sorting.field === 'cnpj' && (
                          sorting.order === 'asc' ? (
                            <ArrowUpwardIcon className="sortIcon" />
                          ) : (
                            <ArrowDownwardIcon className="sortIcon" />
                          )
                        )}
                    </TableCell>
                    <TableCell className="tableCell">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {users.map((user) => (
                  <TableRow key={user.id}>
                    <TableCell className="tableCell">{user.id}</TableCell>
                    <TableCell className="tableCell">{user.nickname}</TableCell>
                    <TableCell className="tableCell">{user.phone}</TableCell>
                    <TableCell className="tableCell">{user.cnpj}</TableCell>
                    <TableCell className="tableCell">
                        <IconButton
                          className="actionIcon"
                          onClick={() => handleEditCustomer(user.id)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          className="actionIcon"
                          onClick={() => handleDeleteCustomer(user.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </main>
    </>
  );
}

export default CustomerPage;