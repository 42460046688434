import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { CircularProgress, IconButton } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

function Download({ isLoading, certificateData }) {
  const user = useSelector((state) => state.auth.user);
  const [downloadSuccess, setDownloadSuccess] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    if (downloadSuccess) {
      const timer = setTimeout(() => {
        setDownloadSuccess(false);
      }, 5000); // 5 seconds

      return () => clearTimeout(timer);
    }
  }, [downloadSuccess]);
  
  const downloadCertificate = async () => {
    console.log('Download process started');
    setIsButtonDisabled(true);

    try {
        const { id, customer_id } = certificateData;
        if (!id || !user.customer_id) {
            console.error('ID or customer_id not found in certificateData');
            throw new Error('ID or customer_id not found');
        }

        const payload = {
            customer_id: user.customer_id,
            order_number: id
        };

        console.log('Payload being sent to API:', payload);
        console.log('Fetching certificate from API...');

        const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/provider/certificate/collect`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        });

        console.log('API response received:', response);

        if (!response.ok) {
            console.log('API response not OK. Status:', response.status);
            throw new Error(`API request failed with status ${response.status}`);
        }

      // directly in the response body as text
      const base64Response = await response.text();
      console.log('Base64 response:', base64Response);

      const blob = base64ToBlob(base64Response, 'application/zip');
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = `${certificateData.id || 'certificate'}.zip`;
      // file deepcode ignore DOMXSS: <The file comes by Sectigo environment>
      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
      console.log('Download initiated and temporary URL revoked');
      setDownloadSuccess(true);
  } catch (error) {
      console.error('Error during download process:', error);
  } finally {
      setIsButtonDisabled(false);
      console.log('Download process completed. Button re-enabled.');
  }
};

  // Helper function to convert a base64 string to a Blob
  const base64ToBlob = (base64, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  return (
    <div>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          {downloadSuccess ? (
            <p>Download complete!</p>
          ) : (
            <IconButton
              color="primary"
              aria-label="download"
              onClick={downloadCertificate}
              disabled={isButtonDisabled}
            >
              <CloudDownloadIcon />
            </IconButton>
          )}
        </>
      )}
    </div>
  );
}

Download.propTypes = {
  isLoading: PropTypes.bool,
  certificateData: PropTypes.object.isRequired,
};

export default Download;
