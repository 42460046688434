import React from 'react';
import '../../styles.css';

function BackgroundVideo() {
  return (
    <div className="background-video">
      <video autoPlay muted loop>
        <source src="https://www.trustcert.com.br/wp-content/uploads/2023/09/banner.mp4" type="video/mp4" />
        Seu navegador não suporta a reprodução de vídeos.
      </video>
      <div className="video-mask"></div>
    </div>
  );
}

export default BackgroundVideo;
