// AddUserForm.js

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

function AddUserForm({ open, onClose }) {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const user = useSelector((state) => state.auth.user);
  const customerId = user.customer_id;

  useEffect(() => {
    if (isAuthenticated && user) {
      console.log(`Usuário ${user.username} está autenticado.`);
      console.log(`Customer ID do usuário: ${user.customer_id}`);
    }
  }, [isAuthenticated, user]);

  const isAdminFieldDisabled =
    !(customerId && customerId.length === 49 && customerId.charAt(16) === '1');
  const isCustomerIdFieldDisabled =
    !(customerId && (customerId.length === 49 || customerId.length === 50));

    let extractedChars = '';
    if (customerId && customerId.length === 49) {
      extractedChars = customerId.charAt(16);
    } else if (customerId && customerId.length === 50) {
      extractedChars = customerId.substring(16, 18);
    }

  const [formData, setFormData] = useState({
    email: '',
    name: '',
    phone: '',
    cpf: '',
    is_admin: false,
    password: '',
    customer_id: extractedChars,
  });

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const fieldValue = type === 'checkbox' ? checked : value;
    setFormData((prevData) => ({
      ...prevData,
      [name]: fieldValue,
    }));
  };

  const handleAdd = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      console.log('data: ', response);
      if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
      }
  
      const responseData = await response.json();
      console.log('User added:', responseData);
  
      onClose();
    } catch (error) {
      console.error('Error adding new user:', error);
    }
  };  


  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add User</DialogTitle>
      <DialogContent>
        <TextField
          name="email"
          label="Email"
          value={formData.email}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          name="name"
          label="Name"
          value={formData.name}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          name="phone"
          label="Phone"
          value={formData.phone}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          name="cpf"
          label="CPF"
          value={formData.cpf}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          name="customer_id"
          label="Customer ID"
          value={formData.customer_id}
          onChange={handleChange}
          fullWidth
          margin="normal"
          disabled={isCustomerIdFieldDisabled}
        />
        <FormControlLabel
          control={
            <Checkbox
              name="is_admin"
              checked={formData.is_admin}
              onChange={handleChange}
              disabled={isAdminFieldDisabled}
            />
          }
          label="Admin"
        />
        {/* You can display password here if needed */}
        <TextField
          name="password"
          label="Password"
          type="password"
          value={formData.password}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleAdd} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddUserForm;
