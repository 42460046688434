import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { addLog } from '../logs/logsSlice';

// Estado inicial
const initialState = {
  isAuthenticated: false,
  user: null,
  token: localStorage.getItem('token') || null,
  customer_id: localStorage.getItem('customer_id') || null,
  company: localStorage.getItem('company') || null,
  isAdmin: localStorage.getItem('isAdmin') === 'true',
  lastInteraction: localStorage.getItem('lastInteraction') || null,
};

// Ação assíncrona para realizar o login
export const loginAsync = createAsyncThunk('auth/login', async ({ username, password }, { dispatch }) => {
  try {
    const requestOptions = {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        "email": username,
        "password": password,
        "product": "trustcert",
      }),
      redirect: 'follow',
      credentials: 'include'
    };

    const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/login`, requestOptions);
    if (!response.ok) throw new Error('A tentativa de login falhou');
    const data = await response.json();

    const userDetails = { ...data, username };

    dispatch(login(userDetails));
    console.log(`Usuário ${username} fez login com sucesso`);

    return userDetails;
  } catch (error) {
    console.error('A tentativa de login falhou:', error.message);
    throw error;
  }
});

// Ação assíncrona para realizar o logout
export const logoutAsync = createAsyncThunk('auth/logout', async (_, { dispatch }) => {
  dispatch(logout()); // Use a ação logout do slice
  dispatch(addLog('Usuário fez logout'));
});

export const checkAuth = createAsyncThunk('auth/checkAuth', async (_, { dispatch }) => {
  const token = localStorage.getItem('token');
  const customer_id = localStorage.getItem('customer_id');
  const isAdmin = localStorage.getItem('isAdmin');
  const username = localStorage.getItem('username');
  const company = localStorage.getItem('company');

  if (token && customer_id && isAdmin) {
    const userDetails = {
      token,
      customer_id,
      username,
      isAdmin: isAdmin === true,
      company

    };

    dispatch(login(userDetails));
  } else {
    dispatch(logout());
  }
});


const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      const { token, customer_id, isAdmin, username, company } = action.payload;
      state.isAuthenticated = true;
      state.user = action.payload;
      state.token = token;
      state.customer_id = customer_id;
      state.isAdmin = isAdmin;
      state.company = company;
      state.lastInteraction = Date.now().toString();
      localStorage.setItem('token', token);
      localStorage.setItem('username', username);
      localStorage.setItem('customer_id', customer_id);
      localStorage.setItem('isAdmin', isAdmin.toString());
      localStorage.setItem('company', company);
      localStorage.setItem('lastInteraction', state.lastInteraction);
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.token = null;
      state.customer_id = null;
      state.isAdmin = null;
      state.company = null;
      state.lastInteraction = null;
      localStorage.removeItem('token');
      localStorage.removeItem('username');
      localStorage.removeItem('customer_id');
      localStorage.removeItem('isAdmin');
      localStorage.removeItem('company');
      localStorage.removeItem('lastInteraction');
    },
  },
});

export const { login, logout } = authSlice.actions;

export default authSlice.reducer;
