// logsSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const logsSlice = createSlice({
  name: 'logs',
  initialState,
  reducers: {
    addLog: (state, action) => {
      state.push(action.payload);
    },
  },
});

export const { addLog } = logsSlice.actions;
// Selector personalizado para obter o nome do usuário
export function selectUserLogs(userName) {
  return (state) => {
    return state.logs.filter((log) => log.toLowerCase().includes('usuário') && log.includes(userName));
  };
}

export default logsSlice.reducer;