// utils.js
import UAParser from 'ua-parser-js';

// Função para obter informações do user-agent
export function getUserAgentInfo() {
  const parser = new UAParser();
  const userAgentInfo = parser.getResult();
  
  return userAgentInfo;
}

// Função para gerar uma senha forte
export function generateStrongPassword(length = 12) {
  const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
  const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const numericChars = '0123456789';
  const specialChars = '!@#$%^&*()_-+=<>?';

  const allChars = lowercaseChars + uppercaseChars + numericChars + specialChars;

  let password = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * allChars.length);
    password += allChars.charAt(randomIndex);
  }

  return password;
}
