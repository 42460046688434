import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';

function Upload() {
  const [isLoading, setIsLoading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const customerId = useSelector((state) => state.auth.customer_id);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const apiUrl = `${process.env.REACT_APP_API_URL}/v1/add_certificates`;

  const addCertificate = async (certificateData) => {
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(certificateData),
      });

      if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
      }

      console.log('Certificate added successfully');

      // Defina uploadSuccess como true após o upload bem-sucedido
      setUploadSuccess(true);
    } catch (error) {
      console.error('Error adding certificate:', error);
    } finally {
      setIsButtonDisabled(false);
    }
  };

  const handleFileUpload = async (event) => {
    setIsButtonDisabled(true);
    setIsLoading(true);
    const file = event.target.files[0];
    const reader = new FileReader();

    let ignoreHeader = true;

    reader.onload = async (e) => {
      try {
        const fileContent = e.target.result;
        const lines = fileContent.split('\n');

        for (const line of lines) {
          if (ignoreHeader) {
            ignoreHeader = false;
            continue;
          }

          const [url, startDate, expirationDate] = line.split(',');

          const formattedStartDate = new Date(startDate.trim()).toISOString();
          const formattedExpirationDate = new Date(expirationDate.trim()).toISOString();

          const certificateData = {
            url: url.trim(),
            start_date: formattedStartDate,
            expiration_date: formattedExpirationDate,
            customer_id: customerId,
          };

          await addCertificate(certificateData);
          console.log(certificateData);
        }

        setIsLoading(false);
      } catch (error) {
        console.error('Error processing file:', error);
      } finally {
        setIsLoading(false);
        setIsButtonDisabled(false);
      }
    };

    if (file) {
      reader.readAsText(file);
    }
  };

  return (
    <>
      <input
        accept=".csv,.cer,.pem,.der"
        style={{ display: 'none' }}
        id="contained-button-file"
        type="file"
        onChange={handleFileUpload}
      />
      <label htmlFor="contained-button-file">
        <Typography
            variant="contained"
            color="inherit"
            component="span"
            disabled={isLoading || isButtonDisabled}
            sx={{
                backgroundColor: 'transparent',
                color: 'black',
                '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)'
                },
                '&.Mui-disabled': {
                    backgroundColor: 'transparent',
                    color: 'rgba(0, 0, 0, 0.26)'
                }
            }}
        >
            {isLoading ? 'Uploading...' : 'Upload '}
        </Typography>

      </label>
    </>
  );
}

Upload.propTypes = {
  isLoading: PropTypes.bool,
};

export default Upload;
